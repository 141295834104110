<template>
    <div>
        <van-empty style="height: calc(100vh - 50px)" description="没有消息" v-if="!isLoading && list.length<1" />
        <div class="list" v-if="list.length>0">
            <template v-for="(item,index) in list">
                <div class="item" @click="toDetail(item)">
                    <div class="left">
                        <div class="thumb">
                            <van-image
                                    width="40px"
                                    height="40px"
                                    :src="imageUrl + item.avatar"
                            />
                        </div>
                        <van-icon v-show="item.s_unread>0" :badge="item.s_unread"/>
                        <div class="info">
                            <div class="nickname">{{item.nickname}}</div>
                            <div class="desc">{{item.last_message}}</div>
                        </div>
                    </div>
                    <div class="right">
                        <span class="date">{{item.date}}</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { getStoreChatGroup } from '@/api/chat'
    import { info } from '@/api/user'
    import http from '../../utils/config.js'

    export default {
        name: "ChatList",
        data() {
            return {
                isLoading:true,
                list: [],
                user: {},
	            imageUrl:http.imageUrl,
            };
        },
        //初始化分组
        created() {
            this.load();
        },
        //初始化分组
        methods: {
            load() {
                info().then((res) => {
                    this.user = res.data.info
                })
                this.getGroup();
            },

            getGroup(){
                this.isLoading = true;
                //获取分组列表
                getStoreChatGroup({
                    page: 1,
                    size: 500 //获取所有分组
                }).then((res) => {
                    if(res.hasOwnProperty('data')){
                        this.list = res.data;
                    }
                    this.isLoading = false;
                });
            },
            //跳转到详情
            toDetail(item){
                this.$router.push({
                    path:'/chat/page',
                    query:{
                        id:item.id,
                        nickname:item.nickname,
                        sid:this.user.id,
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .list {
        background: #ffffff;
        padding: 15px 15px 50px 15px;

        .item {
            padding: 12px 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            border-bottom: 1px solid #f9f9f9;
            .left {
                display: flex;
                .info {
                    margin-left: 12px;
                    .nickname {
                        font-size: 14px;
                        color: #444444;
                    }
                    .desc {
                        margin-top: 5px;
                        color: #939393;
                        font-size: 12px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        max-width: 200px;
                    }
                }
            }

            .right {
                .date {
                    color: #969696;
                    font-size: 12px;
                }
            }
        }
    }
</style>
